<template>
  <div>
    <c-tab
      ref="plantDocuDetail"
      type="horizon"
      :height="tabHeight"
      :tabItems.sync="tabItems"
      :dense="true"
      :inlineLabel="true"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :emerge="emerge"
          :key="tab.key"
          :contentHeight.sync="contentHeight"
          @closePopup="closePopup"
          @changeStatus="changeStatus"
          @transInfo="transInfo"
          @getDetail="getDetail"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'emergency-scenario-eval-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        trainingScenarioId: '',
        plantCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      emerge: {
        trainingScenarioId: '',
        deptCd: '',
        trainingScenarioName: '',
        emergencyPlanName: '',
        plantCd: '',
        useFlag: 'Y',
        scenarios: [],
        deleteScenarios: [],
        evalItems: [],
        deleteEvalItems: [],
        regUserId: '',
        chgUserId: '',
      },
      tab: 'base',
      editable: true,
      getSituationUrl: '',
      tabItems: [],
      addTabItems: [],
    };
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 45) + 'px';
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },

  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.getUrl = selectConfig.sop.eap.emergTraining.get.url;
      // code setting
      this.tabItems = [
        // 시나리오
        { name: 'base', icon: 'foundation', label: '시나리오', component: () => import(`${'./emergencyScenario.vue'}`), key: uid() },
      ];
      this.addTabItems = [
        // 훈련항목
        { name: 'traningScenario', icon: 'account_tree', label: '평가항목', component: () => import(`${'./emergencyEval.vue'}`) },
      ];
      if (this.popupParam.trainingScenarioId) {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
      // list setting
      this.getDetail();
    },
    transInfo(data) {
      this.$emit('transInfo', data);
    },
    changeStatus(data) {
      this.tabItems = this.tabItems.concat(this.addTabItems);
      this.popupParam.trainingScenarioId = data;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.trainingScenarioId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.trainingScenarioId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.emerge = _result.data;
          this.updateMode = true;
          this.$emit('setRegInfo', _result.data)
        },);
      }
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
  }
};
</script>
